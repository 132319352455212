import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from '../components/seo';

const Colaborador = () => {
  return (
    <Layout>
      <SEO />
    </Layout>
  )
}

export default Colaborador

export const pageQuery = graphql`
  query EquipeQuery($id: String!) {
    markdownRemark( 
      id: { eq: $id }
    ) {
      id
      html
      frontmatter {
        slug
        title
        nome
        nomeCompleto
        cargo
        ordem
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1980, maxHeight: 768, quality: 100, srcSetBreakpoints: [350, 700, 1050, 1400]) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`